<template>
  <v-app id="inspire">
    <v-app-bar id="scroll-target" app color="white" flat>
      <v-app-bar-nav-icon
        class="d-flex d-sm-none"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <v-avatar size="64"
        ><img src="../assets/128.png" alt="Sree Dattha" />
      </v-avatar>
      <!-- <v-toolbar-title>Interio</v-toolbar-title> -->

      <!-- <v-toolbar-items right class="ml-n1" color="grey darken-1">
        <v-btn v-for="link in links" :key="link" text>
          {{ link }}
        </v-btn>
      </v-toolbar-items> -->

      <v-tabs v-model="tab" class="ml-n1 tab-width d-none d-sm-flex" right>
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab
          v-for="(link, i) in links"
          :key="i"
          :to="link.route"
        >
          {{ link.menu }}
        </v-tab>
      </v-tabs>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="btn-quote btn-primary-c"
            rounded
            dark
            v-bind="attrs"
            v-on="on"
          >
            Get A Quote
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <h4 class="section-title">LET'S GET STARTED</h4>
          </v-card-title>
          <v-card-text>
            <v-container class="get-a-quote">
              <v-row>
                <v-col cols="12">
                  <v-text-field label="First Name*" required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field label="Last Name*"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Mobile Number*"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Email*"
                    type="email"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-chip-group active-class="primary" column>
                    <v-chip v-for="tag in tags" :key="tag">
                      {{ tag }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialog = false"> CANCEL </v-btn>
            <v-btn
              class="btn-primary-c theme--dark v-btn"
              @click="dialog = false"
            >
              SUBMIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app-bar>
    <!-- Add a navigation bar -->
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-for="(item, index) in links" :key="index">
            <v-list-item-title @click="tab = index">{{
              item.menu
            }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Navigation bar ends -->
    <v-btn
      v-show="fab"
      v-scroll="onScroll"
      fab
      dark
      fixed
      bottom
      right
      class="btn-primary-c"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-main>
      <v-container>
        <div class="flex head-title">
         <v-btn
            class="btn-quote btn-primary-c"
            rounded
            dark
             to="/"
            v-bind="attrs"
            v-on="on"
            
          >
            Back
          </v-btn>
        <h2 class="section-title">Interior Designs</h2></div>
        <v-layout wrap class="my-5">
          <v-card
            v-for="(item, i) in services"
            :key="i"
            class="mx-auto my-5"
            max-width="355"
          >
            <v-img :src="item.image" height="350px" />
          </v-card>
        </v-layout>
      </v-container>
    </v-main>
    <v-footer dark padless class="footer">
      <v-container>
        <v-layout wrap class="my-5">
          <v-col cols="12" sm="12" md="4">
            <v-card class="mx-auto sr-box-shadow">
              <v-card-text class="footer-text">
                <v-card-title class="footer-h4"
                  >SREE DATTHA CNC CRAFTS & INTERIO </v-card-title
                ><v-divider></v-divider>

                <div class="footer-content">
                  Our aim is to be an organization of value, respect and
                  transparency and to continuously innovate, improve and deliver
                  efficient and effective Interior solutions.
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="4"> </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-card class="mx-auto sr-box-shadow">
              <v-card-text class="footer-text">
                <v-card-title class="footer-h4">FOLLOW US</v-card-title>
                <v-divider></v-divider>
                <div class="footer-content">
                  <v-btn
                    v-for="(social, i) in socials"
                    :key="i"
                    class="social-icons"
                    :color="social.color"
                    icon
                    :href="social.link"
                    target="_blank"
                  >
                    <v-icon size="24px" class="social-icons-color">
                      {{ social.icon }}
                    </v-icon>
                  </v-btn>
                </div>
              </v-card-text></v-card
            >
          </v-col>
        </v-layout>
      </v-container>
      <v-layout class="footer-bottom">
        <v-row class="d-flex justify-center copy-right my-1">
          <span class="cpy-right">©Copyright</span>
          <strong
            ><b>Sree Dattha CNC Crafts & Interio.</b> All Rights
            Reserved</strong
          >
        </v-row>
      </v-layout>
    </v-footer>
  </v-app>
</template>
<script>
export default {
  data: () => ({
     links: [
      
    ],
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    socials: [
      {
        icon: "mdi-facebook",
        color: "indigo",
        link: "https://www.facebook.com/profile.php?id=100076363030472",
      },
      {
        icon: "mdi-linkedin",
        color: "cyan darken-1",
        link: "",
      },
      {
        icon: "mdi-instagram",
        color: "red lighten-3",
        link: "https://www.instagram.com/sreedatthacnccraftsinterio/",
      },
    ],
    services: [
      {
        image: require("@/assets/images/interiors/1.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/2.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/3.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/4.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/5.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/6.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/7.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/8.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/9.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/10.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/11.jpeg"),
      },
      {
        image: require("@/assets/images/interiors/12.jpeg"),
      },
    ],
  }),
};
</script>

